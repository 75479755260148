<template>
    <master-panel dark>
        <template v-slot:body>
            <v-row class="text-center">
                <v-col cols="12">
                    <div class="title">
                        Bienvenido a Cx Smart
                    </div>
                    <div class="subtitle">
                        Copie el siguiente link y súbalo a su sitio web, en sus
                        redes sociales o envíelo a su secretaria para que sus
                        pacientes interesados en una cirugía bariátrica,
                        puedan responder el cuestionario e iniciar el proceso.
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-btn color="#ffffff" x-large depressed class="normal-btn" @click="copyLink">
                        Copiar link
                    </v-btn>
                </v-col>
                <v-col cols="12" class="subtitle">
                    Ahora puedes ingresar al dashboard del sistema,
                    donde podrás acceder a las distintas funcionalidades
                    de CxSmart.
                </v-col>
                <v-col cols="12">
                    <v-btn color="secondary" x-large depressed class="normal-btn" @click="goHome">
                        Dashboard
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";

export default {
    name: "InvitationsFinished",
    components: {MasterPanel},
    computed: {
        doctorId(vm = this) {
            return vm.currentUser?.id || null
        }
    },
    methods: {
        copyLink() {
            const vm = this
            const element = document.createElement("textarea")
            element.value = `${vm.baseUrlFront}client/${vm.doctorId}/register`
            document.body.appendChild(element);
            element.select();
            document.execCommand("copy");
            document.body.removeChild(element);
        },
    }
}
</script>

<style scoped>

.title {
    font-size: 26px !important;
    line-height: 28px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.subtitle {
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

</style>